<template>
<section class="content-wrapper">
        <div class="row">
            <vue-snotify></vue-snotify>
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo-beekeeper.png">
                            </div>
                            <h4 class="text-center mt-4">Avez-vous oublié votre mot de passe? </h4>
                            <h6 class="font-weight-light text-center">Un code de récupération sera envoyé à votre adresse email</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <input type="email" v-model="email" class="form-control form-control-lg" id="email" placeholder="Entrer votre adresse mail">
                            </div>
                            <div class="mt-3">
                                <button class="btn btn-block  btn-lg font-weight-medium auth-form-btn" style="background:#0055a4; color:white">Envoyer</button>
                            </div>
                            <div class="text-center mt-4 font-weight-light">
                                Vous n'avez pas de compte? <router-link to="/auth-pages/register" class="text-primary">Créer</router-link>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import axios from 'axios' 
export default {
    name:'forgotPassword',
    data () {
        return {
            email:''
        }
    },
    methods: {
          async handleSubmit () {
            const response = await axios.post('changepasswd',
      { 
        email: this.email
      },
      {
        withCredentials: true,
      })
      this.$snotify.success('Code envoyé', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        })
      console.log({response})
      this.$router.push ({
          name: 'enterKey'
      })
    }
    }
}
</script>